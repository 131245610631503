<template>
  <div>Template Navigation Header</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TemplateNavigationHeader',
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
      footerPagesList: 'GET_FOOTER_PAGES_LIST',
      footerCategoryList: 'GET_FOOTER_CATEGORY_LIST',
      allLandingAreaData: 'GET_ALL_LANDING_AREA_DATA',
      whiteLabel: 'GET_WHITE_LABEL_DATA',
      marketerId: 'GET_MARKETER_ID',
      marketerAgencyContent: 'GET_MARKETER_AGENCY_CONTENT',
      currentPage: 'GET_CURRENT_PAGE',
      isLanding: 'GET_IS_LANDING_PAGE',
      listDestinations: 'GET_LANDING_DESTINATIONS',
    }),
    pageList() {
      const { footerPagesList, footerCategoryList } = this;
      if (!footerPagesList || !footerCategoryList) return null;

      const list = {};
      const pageList = Object.entries(footerPagesList).filter((item) => (item[1].active && item[0] !== 'AgreementApp' && this.filterAvailableDest(item[1].link)));
      footerCategoryList.forEach((cat) => {
        const itemList = pageList.filter((page) => (page[1].category === cat.code));
        itemList.sort((a, b) => (a[1].sort > b[1].sort ? -1 : 1));
        list[cat.code] = itemList;
      });
      return list;
    },
    areaList() {
      const { allLandingAreaData } = this;
      if (!allLandingAreaData) return null;

      return allLandingAreaData.map((item) => ({ title: item.nameHe, link: `/?destination=${item.code}&areaType=true` }));
    },
    srcLogo() {
      const { marketerAgencyContent } = this;
      return (marketerAgencyContent === null) ? this.whiteLabel.logoUrls[0] : marketerAgencyContent.logoUrls[0];
    },
    domesticLink() {
      return process.env.VUE_APP_DOMESTIC_DOMAIN;
    },
  },
  created() {
    this.$store.dispatch('FETCH_ALL_AREA_DATA');
  },
  mounted() {
  },
  methods: {
    filterAvailableDest(link) {
      const { listDestinations } = this;
      if (!link || link.startsWith('http')) return true;
      if (!listDestinations) return false;

      const info = Object.fromEntries(new URLSearchParams(link));
      return listDestinations[info.dealType.trim()]?.findIndex((item) => item.code === info.destination) > -1 || false;
    },
    getLink(item) {
      if (item[1].link) {
        if (item[1].link.startsWith('http')) {
          return item[1].link;
        } else {
          return `/${item[1].link}`;
        }
      }
      return `/${item[1].slug}`;
    },
  },
};
</script>
